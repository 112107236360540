<template>
  <section class="relative z-20 py-12 md:py-16 lg:py-36" :style="sectionStyle">
    <slot></slot>
  </section>
</template>

<script setup>
const parishConfig = useParishConfig()

const sectionStyle = computed(() => ({
  '--bg-svg': `url("data:image/svg+xml,%3Csvg width='1440' height='76' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(0, 76) scale(1, -1)'%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath stroke='null' d='m0,202c0,0 0,-193.18569 0,-193.18569c111.82143,-6.53794 223.64286,-13.07587 345,-1.30759c121.35714,11.76828 252.25,41.84277 359,55.57242c106.75,13.72966 189.35714,11.11449 308,-0.65379c118.64286,-11.76828 273.32143,-32.68966 428,-53.61104c0,0 0,196.13796 0,196.13796l-1440,-2.95227z' stroke-width='0' fill='${encodeURIComponent(parishConfig.value.colors.secondary.dark)}' id='svg_1' /%3E%3C/g%3E%3C/svg%3E")`,
  '--bg-svg-bottom': `url("data:image/svg+xml,%3Csvg width='1440' height='76' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(0, 76) scale(1, -1)'%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath stroke='null' d='m0,202c0,0 0,-193.18569 0,-193.18569c111.82143,-6.53794 223.64286,-13.07587 345,-1.30759c121.35714,11.76828 252.25,41.84277 359,55.57242c106.75,13.72966 189.35714,11.11449 308,-0.65379c118.64286,-11.76828 273.32143,-32.68966 428,-53.61104c0,0 0,196.13796 0,196.13796l-1440,-2.95227z' stroke-width='0' fill='${encodeURIComponent(parishConfig.value.colors.secondary.dark)}' id='svg_1' transform='scale(1, -1) translate(0, -76)' /%3E%3C/g%3E%3C/svg%3E")`,
}))
</script>

<style scoped>
section {
  background:
    var(--bg-svg) center top no-repeat,
    var(--bg-svg-bottom) center bottom no-repeat;
  @apply bg-contain;
}
</style>
