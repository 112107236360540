<template>
  <AppHeader />
  <ClientOnly>
    <template #fallback>
      <div class="h-[210px] w-full flex items-center justify-center">
        <UtilitySpinner />
      </div>
    </template>
    <HomepageHeader v-if="homepage.slideshow?.data?.length || homepage.topSites?.length" />
  </ClientOnly>

  <BackgroundGradient
    :class="
      homepage.thought && Object.keys(liturgicalCalendar).length
        ? `pb-20 lg:pb-72`
        : homepage.thought && !Object.keys(liturgicalCalendar).length
          ? 'pb-20 lg:pb-44'
          : ''
    "
  >
    <LazyHomepageIntro class="pt-14 lg:pt-32" />
    <AnimatedObserver>
      <LazyHomepageOffice />
    </AnimatedObserver>
    <AnimatedObserver>
      <LazyHomepageArticles />
    </AnimatedObserver>
  </BackgroundGradient>
  <BackgroundWave v-if="homepage.thought" class="!bg-secondary-light">
    <LazyHomepageLitCal v-if="Object.keys(liturgicalCalendar).length" class="lg:!absolute lg:-top-20 lg:left-1/2 transform lg:-translate-x-1/2" />
    <AnimatedObserver>
      <LazyHomepageThought :class="Object.keys(liturgicalCalendar).length ? 'lg:pt-52' : 'lg:pt-44'" />
    </AnimatedObserver>
  </BackgroundWave>
  <section v-else-if="Object.keys(liturgicalCalendar).length" class="bg-secondary-dark relative z-20">
    <LazyHomepageLitCal />
  </section>
  <LazyPodcasts v-if="Object.keys(podcasts).length > 1" class="pt-12 lg:pt-32 pb-20 lg:pb-44" />
  <LazyAppFooter :class="[Object.keys(podcasts).length > 1 ? 'pt-10' : 'pt-12 lg:pt-32']" />
</template>

<script setup lang="ts">
import { isOffline } from '~/utils/helpers';
import type { Seo } from '~/utils/types';

const route = useRoute()
const { gqlRootNames, gqlChunks, gqlProps, gqlMeta } = useGlobalGql()
const adminFetch = useAdminFetch()
const homepage = useHomepage()
const articles = useHomepageArticles()
const office = useOffice()
const podcasts = usePodcasts()
const seo = useGlobalSeo()
const liturgicalCalendar = useLiturgicalCalendar()
const { ToSeoObject } = useMappers()

if (route.query?.refresh) {
  homepage.value = {}
  articles.value = []
  office.value = {}
  liturgicalCalendar.value = {}
}

if (!Object.keys(homepage.value).length) {
  gqlRootNames.push('homepage')
  gqlChunks.push(['heading', 'slideshow', 'topSites', 'importantNotice', 'articles', 'office', 'thought', 'seo'])
  gqlProps.push(undefined)
  gqlMeta.push(false)
}

if (!Object.keys(articles.value).length) {
  gqlRootNames.push('articles')
  gqlChunks.push(['basic', 'description'])
  gqlProps.push(`filters: { onHomepage: { eq: true } } sort: "publishedAt:desc" pagination: { limit: 3 }`)
  gqlMeta.push(false)
}

if (!Object.keys(office.value).length) {
  gqlRootNames.push('office')
  gqlChunks.push(['officeHours'])
  gqlProps.push(undefined)
  gqlMeta.push(false)
}

if (gqlRootNames.length && !isOffline()) {
  try {
    const {
      homepage: gqlHomepage = {},
      articles: gqlArticles = {},
      office: gqlOffice = {},
    } = await adminFetch(gqlRootNames, gqlChunks, gqlProps, gqlMeta)

    homepage.value = gqlHomepage.data?.attributes || homepage.value
    articles.value = gqlArticles.data?.map((article: any) => article.attributes) || articles.value
    office.value = gqlOffice.data?.attributes || office.value
  } catch (e: any) {
    showError(e)
  }
}

if (!Object.keys(liturgicalCalendar.value).length) {
  try {
    const { data, error }: { data: any; error: any } = await useFetch('/api/litcal')
    if (error.value) throw error.value
    liturgicalCalendar.value = data.value
  } catch (error: any) {
    console.error(error)
  }
}

useSeoMeta({
  ...seo.value,
  ...(ToSeoObject(homepage.value?.seo) as Seo | null),
})
</script>
