<template>
  <article class="relative h-full">
    <div class="absolute top-0 left-0 bg-gradient-to-b from-transparent to-black/80 w-full h-full"></div>
    <img :src="url" :alt="alt" class="w-full h-full object-cover" />
    <p class="absolute bottom-0 left-0 px-[24px] pb-[22px] lg:px-7 lg:pb-4 font-serif text-white font-bold text-lg">
      {{ heading }}
    </p>
  </article>
</template>
<script setup>
const fixUrl = useFixUrl()
const props = defineProps({
  heading: String,
  image: String,
  alt: String,
})
const url = computed(() => fixUrl(props.image))
</script>
