<template>
  <UtilitySwiper
    v-if="filteredSites.length"
    :slides-per-view="1.2"
    :space-between="20"
    :breakpoints="{
      640: {
        slidesPerView: 1.5,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 26.25,
      },
    }"
  >
    <swiper-slide v-for="(site, index) in filteredSites" :key="index" class="!h-auto last-of-type:pr-6 last-of-type:lg:pr-0">
      <NuxtLink :to="site.url" :target="site.url.startsWith('http') ? '_blank' : '_self'" :rel="site.url.startsWith('http') ? 'noopener' : ''">
        <UtilityImageAsLink
          :heading="site.heading"
          :image="site.image?.data?.attributes?.url"
          :alt="site.image?.data?.attributes?.alternativeText || site.image?.data?.attributes?.name"
        />
      </NuxtLink>
    </swiper-slide>
  </UtilitySwiper>
</template>

<script setup>
const props = defineProps({
  sites: Array,
})

const filteredSites = []
for (const site of props.sites) {
  if (filteredSites.length >= 4) break
  filteredSites.push(site)
}
</script>
