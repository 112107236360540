<template>
  <div class="animate__animated" :class="[animateClassComputed, animateSpeedComputed]" ref="observerRef">
    <slot />
  </div>
</template>
<script setup lang="ts">
import 'animate.css'

const emit = defineEmits(['intersect'])
const props = defineProps({
  options: {
    type: Object,
    default: null,
  },
  animateClass: {
    type: String,
    default: 'animate__fadeInUp',
  },
  speed: {
    type: String,
    default: 'fast',
  },
})
const observerRef = ref(null)
const intersected = ref(false)
const animateClassComputed = computed(() => (intersected.value ? props.animateClass : ''))
const animateSpeedComputed = computed(() => (props.speed !== 'normal' ? `animate__${props.speed}` : ''))
let observer: any

onMounted(() => {
  const observerOptions = props.options || {}
  observer = new IntersectionObserver(([entry]) => {
    if (entry && entry.isIntersecting) {
      intersected.value = true
      emit('intersect')
    }
  }, observerOptions)
  observer.observe(observerRef.value)
})

onUnmounted(() => {
  observer.disconnect()
})
</script>
